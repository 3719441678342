import "./Cards.scss";

const Card = (props: CardType) => {
  return (
    <div className="card">
      <div
        className="divImg"
        style={{ backgroundImage: `url(${props.img})` }}
      />
      <div className="title">{props.title}</div>
      <div className="button">{props.button}</div>
    </div>
  );
};

export default Card;
