import Homepage from "../pages/Homepage";
import { Navigate, useRoutes } from "react-router-dom";
import Test3D from "../pages/Test3D";

const APP_ROUTES = [
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/le-petit-jouteur",
    element: <Homepage />,
  },
  {
    path: "/le-forge",
    element: <Test3D />,
  },
  {
    path: "/matorral",
    element: <Homepage />,
  },
  {
    path: "/contact",
    element: <Homepage />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

const RoutesComponent = () => {
  const routes = useRoutes(APP_ROUTES);
  return routes;
};

export default RoutesComponent;
