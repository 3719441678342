import { useEffect, useState } from "react";
import Menu from "../Menu/Menu";
import cx from "classnames";
import "./Header.scss";

const menuHeaderLeft: { title: string; menu?: MenuType; redirect?: string }[] =
  [
    {
      title: "Le petit jouteur",
      redirect: "",
    },
    {
      title: "le forgé",
      redirect: "/le-forge",
    },
    {
      title: "Matorral",
      redirect: "",
    },
  ];

const menuHeaderRight: { title: string; menu?: MenuType; redirect?: string }[] =
  [
    {
      title: "la maison d'auzier",
      menu: [
        {
          title: "Qui sommes nous ?",
          redirect: "",
        },
        {
          title: "La fondation Auzier",
          redirect: "",
        },
        {
          title: "Actualités",
          redirect: "",
        },
        {
          title: "L'art d'offrir",
          redirect: "",
        },
      ],
    },
    {
      title: "Visites de nos ateliers",
      menu: [
        {
          title: "1",
          redirect: "",
        },
        {
          title: "2",
          redirect: "",
        },
      ],
    },
    {
      title: "Contact",
    },
  ];

const Header = () => {
  const TOP_OFFSET = 70;
  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={cx("header", { displayBackground: showBackground })}>
      <div>
        <div className="header-menu">
          {menuHeaderLeft.map((menu) => (
            <Menu type="header" {...menu} />
          ))}
        </div>
        <div id="logo-header">
          <a href="/">
            <img
              src={`/images/auzier-logo${showBackground ? "" : "-white"}.png`}
              alt="Logo"
            />
          </a>
        </div>
        <div className="header-menu">
          {menuHeaderRight.map((menu) => (
            <Menu type="header" {...menu} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
