import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const Render3D = () => {
  const mount = useRef<any>(null);
  const [textures, setTextures] = useState<THREE.Texture[]>([]);
  const [selectedTextureId, setSelectedTextureId] = useState<
    string | undefined
  >();
  const scene = new THREE.Scene();
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  const ambiantLight = new THREE.AmbientLight("white", 0.3);
  const directionLight = new THREE.DirectionalLight("white", 2.5);
  const camera = new THREE.PerspectiveCamera(
    5,
    window.innerWidth / window.innerHeight,
    0.1,
    100
  );
  const spotlight = new THREE.SpotLight();
  const controls = new OrbitControls(camera, renderer.domElement);
  const loader = new GLTFLoader();
  const hemiLight = new THREE.HemisphereLight();

  useEffect(() => {
    spotlight.position.set(1, 1, 1);
    spotlight.color = new THREE.Color("white");
    scene.add(spotlight);
    scene.add(hemiLight);
    scene.add(ambiantLight);
    scene.add(directionLight);
    camera.position.z = 5;
    renderer.setClearColor(0x212121);
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    controls.enableDamping = true;
    loader.load(
      "https://files.auzier.silverscreenshot.com/couteauTest.glb",
      function (gltf) {
        const loadedTextures: THREE.Texture[] = [];
        scene.add(gltf.scene);
        gltf.scene.traverse((child: any) => {
          if (child.isMesh) {
            loadedTextures.push(child);
          }
        });
        setTextures(loadedTextures);
      },
      (xhr) => {
        if (xhr.loaded === xhr.total) console.log("loaded");
      },
      function (error) {
        console.error(error);
      }
    );

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();
    return () => {
      renderer.dispose();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTextureId) {
      const currentTexture: any = textures.find(
        (t) => t.uuid === selectedTextureId
      );
      console.log("currentTexture", currentTexture);
      console.log("scene", scene);

      const loader = new THREE.TextureLoader();
      loader.load(
        currentTexture,
        function (texture) {
          scene.traverse((child: any) => {
            if (child.isMesh) {
              child.material.map = texture;
              child.material.needsUpdate = true;
            }
          });
          renderer.render(scene, camera);
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTextureId, scene]);

  return (
    <>
      <ul>
        {textures.length &&
          textures.map((texture, index) => (
            <li
              onClick={() => setSelectedTextureId(texture.uuid)}
              key={index}
              // style={{
              //   color: texture.uuid === selectedTextureId ? "red" : "black",
              // }}
            >
              <button>{texture.name}</button>
            </li>
          ))}
      </ul>
      <div ref={mount} />
    </>
  );
};

export default Render3D;
