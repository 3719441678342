import Link from "../Link/Link";
import { useState } from "react";
import cx from "classnames";
import "./Menu.scss";

const Menu = ({
  title,
  menu,
  type,
  redirect,
}: {
  title: string;
  menu?: MenuType;
  type: "footer" | "header";
  redirect?: string;
}) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div
      onMouseOver={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
      className={cx("menu-main", {
        footerType: type === "footer",
        headerType: type === "header",
      })}
    >
      <Link to={redirect ?? ""}>
        <h5>{title}</h5>
        {/* {menu && menu.length > 0 && "v"} */}
      </Link>
      {menu && menu.length > 0 && (
        <div className={cx("menu-list", { active: showMenu })}>
          {menu.map((m) => (
            <div>
              <Link to={m?.redirect ?? ""}>
                <h5>{m.title}</h5>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Menu;
