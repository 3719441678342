import Menu from "../Menu/Menu";
import "./Footer.scss";

const menuFooterLeft: { title: string; menu?: MenuType; redirect?: string }[] =
  [
    {
      title: "Copyright © Auzier.fr",
      redirect: "",
    },
    {
      title: "FAQ",
      redirect: "",
    },
    {
      title: "Conditions générales de ventes",
      redirect: "",
    },
    {
      title: "Carte cadeau",
      redirect: "",
    },
  ];

const menuFooterRight: { title: string; menu?: MenuType; redirect?: string }[] =
  [
    {
      title: "Politique de confidentialité",
      redirect: "",
    },
    {
      title: "Mentions Légales",
      redirect: "",
    },
    {
      title: "Contact",
      redirect: "",
    },
  ];

const Footer = () => {
  return (
    <div id="footer">
      <div>
        <div className="menu-footer">
          {menuFooterLeft.map((menu) => (
            <Menu title={menu.title} type="footer" />
          ))}
        </div>
        <div id="logo-footer">
          <a href="/">
            <img src="/images/auzier-logo.png" alt="LogoFooter" />
          </a>
        </div>
        <div className="menu-footer">
          {menuFooterRight.map((menu) => (
            <Menu title={menu.title} type="footer" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
