import "./Section.scss";

const Section = ({
  title,
  description,
  button,
  img,
}: {
  title: string;
  description: string;
  button: string;
  img: string;
}) => {
  return (
    <div className="section" style={{ backgroundImage: `url(${img})` }}>
      <div className="bgcolor" />
      <div className="info">
        <div>{title}</div>
        <div>{description}</div>
        <button>{button}</button>
      </div>
    </div>
  );
};

export default Section;
