import { Link as LinkRouter } from "react-router-dom";
import "./Link.scss";

// const Link = styled(LinkRouter)`
//   text-decoration: none;
//   color: inherit;
//   &:focus,
//   &:hover,
//   &:visited,
//   &:link,
//   &:active {
//     text-decoration: none;
//   }
// `;

const Link = ({ to, children }: { to: string; children: React.ReactNode }) => (
  <LinkRouter className="link" to={to}>
    {children}
  </LinkRouter>
);

export default Link;
