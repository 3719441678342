import React from "react";
import Render3D from "../components/Render3D";

const Test3D = () => {
  return (
    <div>
      <Render3D />
    </div>
  );
};

export default Test3D;
