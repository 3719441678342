import "./SectionHome.scss";

const SectionHome = () => {
  return (
    <div id="section-home">
      <div>
        <div>Nos couteaux</div>
        <div>Le Petit Jouteur, le Forgé et Mattoral</div>
        <button>Découvrir les collections</button>
      </div>
    </div>
  );
};

export default SectionHome;
