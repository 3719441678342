import Cards from "../components/Cards/Cards";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Section from "../components/Sections/Section/Section";
import SectionHome from "../components/Sections/Home/SectionHome";

const itemsCard = [
  {
    title: "le petit jouteur",
    button: "Découvrir la collection",
    img: "/images/homepage/LePetitJouteurCard.png",
  },
  {
    title: "le forgé",
    button: "Découvrir la collection",
    img: "/images/homepage/LeForgeCard.png",
  },
  {
    title: "matorral",
    button: "Découvrir la collection",
    img: "/images/homepage/LePetitJouteurCard.png",
  },
];

const sectionItems = [
  {
    title: "LA MAISON AUZIER",
    description:
      "La Maison Auzier perpétue la tradition coutelière avec un savoir-faire unique et son esprit de design biomorphique. De fabrication 100% française nos couteaux sont le reflet de nos exigences de qualité et de coupe. Nous vous proposons une magnifique gamme de couteaux avec un choix de manches unique.",
    button: "Qui sommes-nous ?",
    img: "/images/homepage/MaisonAuzier.jpg",
  },
  {
    title: "VISITES DE NOS ATELIERS",
    description:
      "Découvrez l’art de la coutellerie en compagnie d’une équipe de passionnés! Entouré de sites d’exception, en plein cœur de la Vallée de l’Hérault, les ateliers Auzier sont ouverts toute l’année et vous proposent de découvrir les secrets de notre savoir-faire à travers une visite guidée et des ateliers d’initiation à la coutellerie. Venez monter votre couteau et passez un moment inoubliable !",
    button: "Programmez une visite",
    img: "/images/homepage/VisitezNosAteliers.jpg",
  },
  {
    title: "L’ART D’OFFRIR",
    description:
      "La Maison Auzier, cultive le Goût des cadeaux bien enveloppés Paquets et papiers de soie filigranés subliment nos produits selon vos envies.",
    button: "Découvrez nos paquets cadeaux",
    img: "https://files.auzier.silverscreenshot.com/ArtOffrir.gif",
  },
  {
    title: "LA CARTE CADEAU",
    description:
      "Une surprise qui peut en être mille. Personnalisez le montant et le message de votre e-carte cadeaux",
    button: "Acheter une carte cadeau",
    img: "/images/homepage/CarteCadeau.jpg",
  },
];

const Homepage = () => {
  return (
    <>
      <Header />
      <SectionHome />
      <Cards items={itemsCard} />
      {sectionItems.map((item) => (
        <Section {...item} />
      ))}
      <div style={{ width: "100%", height: "200px" }}></div>
      {/* <Render3D /> */}
      <Footer />
    </>
  );
};

export default Homepage;
