import App from "./app/App";
import "./index.css";
import { reportWebVitals } from "./reportWebVitals";
import React, { Suspense } from "react";
import { render } from "react-dom";
import "./fonts/Catamaran-VariableFont_wght.ttf";

render(
  <Suspense fallback={<span>loading...</span>}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
